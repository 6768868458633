import {ChangeDetectionStrategy, Component, inject, model} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivateFn, ResolveFn} from '@angular/router';
import {FormsModule} from '@angular/forms';
import {ModalService} from '../../services/modal.service';
import {GridComponent, IGridColumnDef, IGridConfig, IRowActionEvent} from '../../../oex-ui-kit/components/grid/grid';
import {
  getPageGridModel,
  IPageWithGridModel,
  PageWithGridComponent
} from '../../../oex-ui-kit/base/page-with-grid.component';
import {APIService} from '../../services/api.service';
import {AuthService} from '../../services/auth.service';
import {IStartupMember} from '../../services/api.types';
import {DialogService} from '../../services/dialog.service';

const PENDING_STATUS = 'Invitation sent';
const ADMIN_STATUS = 'Admin';

interface IMembersModel<T> extends IPageWithGridModel<T> {
  slug: string;
}

export const portalMembersCanActivate: CanActivateFn = () => {
  const auth = inject(AuthService);
  return !!auth.user.isStartupAdmin;
}

export const resolveStartupMembers: ResolveFn<IMembersModel<IStartupMember>> = async (route: ActivatedRouteSnapshot) => {
  const api = inject(APIService);
  const auth = inject(AuthService);
  const model = getPageGridModel<IStartupMember>(route) as IMembersModel<IStartupMember>;
  model.slug = auth.user.startup?.slug || '';
  const m = model.slug ? (await api.getStartupMembers(model.slug)).members : [];
  m.forEach(member => {
    member.canBeDeleted = /*member.status !== PENDING_STATUS &&*/ member.status !== ADMIN_STATUS;
    member.canBeTransferred = member.status === ADMIN_STATUS;
  })
  model.data = {
    items: m,
    total: m.length,
    pageSize: m.length,
    currPage: 1
  };
  return model;
}

@Component({
    selector: 'st-members-page',
    imports: [
        FormsModule,
        GridComponent
    ],
    templateUrl: './members-page.component.html',
    styleUrl: './members-page.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MembersPageComponent extends PageWithGridComponent {
  private modal = inject(ModalService);
  private ds = inject(DialogService);
  private api = inject(APIService);

  email = model<string>('');
  override model!: IMembersModel<IStartupMember>;
  columnsDef: IGridColumnDef[] = [
    {text: 'Name', field: 'name'},
    {text: 'Status', field: 'status'},
    {text: 'Created', field: 'created', template: 'date'},
    {text: 'Activated', field: 'activated', template: 'date'},
  ];
  override config: IGridConfig = {
    rowActions: [
      {id: 'delete', icon: '/assets/img/icon-delete.svg', tooltip: 'Remove user', condition: 'canBeDeleted'},
      {
        id: 'transfer',
        icon: '/assets/img/transfer-icon.svg',
        tooltip: 'Transfer admin rights',
        condition: 'canBeTransferred'
      }
    ]
  }
  override resolver = resolveStartupMembers;

  async sendInvitation() {
    if (!this.email()) {
      void this.modal.show('Please enter user email.');
      return;
    }
    const slug = this.model.slug;
    if (!slug) {
      console.error(`Can't invite to startup, because current user don't have startup!`);
      return;
    }
    this.ps.show();
    try {
      await this.api.inviteUserToStartup(slug, this.email())
      void this.refreshData();
    } catch (e) {
      this.modal.showError(e);
    } finally {
      this.ps.hide();
    }
  }

  onGridAction(event: IRowActionEvent<IStartupMember>) {
    switch (event.action.id) {
      case 'delete':
        this.askForDeletion(event.data);
        break;
      case 'transfer':
        this.showTransferDialog();
        break
    }
  }

  private askForDeletion(m: IStartupMember) {
    void this.ds.showConfirmDialog(`Are you sure you want to remove member "${m.name}"?`, () => {
      void this.deleteMember(m);
    });
  }

  private async deleteMember(m: IStartupMember) {
    const slug = this.model.slug;
    if (!slug) {
      console.error(`Can't delete member from startup, because current user don't have startup!`);
      return;
    }
    this.ps.show();
    try {
      await this.api.deleteUserFromStartup(slug, m.id)
      void this.refreshData();
    } catch (e) {
      this.modal.showError(e);
    } finally {
      this.ps.hide();
    }
  }

  private showTransferDialog() {
    const members = this.model.data.items.filter(m => m.status !== ADMIN_STATUS && m.status !== PENDING_STATUS);
    if (!members.length) {
      void this.modal.show('Admin rights can not be transferred. There are no active members.');
      return;
    }
    void this.modal.show({
      title: 'Are you absolutely sure?',
      component: import('./../../dialogs/transfer-admin-dialog/transfer-admin-dialog.component'),
      inputs: {
        slug: this.model.slug,
        members,
        onSuccess: () => this.refreshData()
      }
    });
  }
}
